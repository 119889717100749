<template>
  <div class="mobile-tabbar-container">
    <div class="content">
      <router-link :to="{path: '/'}" class="item" :class="tabBarKey == 'home' ? 'active' : ''">
        <div class="icon img-cover">
          <img v-if="tabBarKey == 'home'" src="../assets/images/tabbar/home-active-icon.png" />
          <img v-else src="../assets/images/tabbar/home-icon.png" />
        </div>
        <div class="name">首页</div>
      </router-link>
      <!-- <router-link :to="{path: '/class'}" class="item" :class="tabBarKey == 'class' ? 'active' : ''">
        <div class="icon img-cover">
          <img v-if="tabBarKey == 'class'" src="../assets/images/tabbar/class-active-icon.png" />
          <img v-else src="../assets/images/tabbar/class-icon.png" />
        </div>
        <div class="name">分类</div>
      </router-link> -->
      <router-link :to="{path: '/order'}" class="item" :class="tabBarKey == 'order' ? 'active' : ''">
        <div class="icon img-cover">
          <img v-if="tabBarKey == 'order'" src="../assets/images/tabbar/order-active-icon.png" />
          <img v-else src="../assets/images/tabbar/order-icon.png" />
        </div>
        <div class="name">订单</div>
      </router-link>
      <router-link :to="{path: '/user'}" class="item" :class="tabBarKey == 'user' ? 'active' : ''">
        <div class="icon img-cover">
          <img v-if="tabBarKey == 'user'" src="../assets/images/tabbar/user-active-icon.png" />
          <img v-else src="../assets/images/tabbar/user-icon.png" />
        </div>
        <div class="name">我的</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileTabBar',
  props: {
    tabBarKey: String
  }
}
</script>

<style scoped lang="less">
.mobile-tabbar-container {
  width: 100%;
  height: 1.04rem;
  .content {
    position: fixed;
    bottom: 0;
    width: var(--max-width);
    left: 50%;
    margin-left: var(---max-width-50);
    height: 1.04rem;
    padding: .12rem .16rem 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    box-shadow: 0px -4px 8px 2px rgba(0, 28, 84, .06);
    z-index: 999;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      width: 1.56rem;
      &.active {
        .name {
          color: #007aff;
        }
      }
      .icon {
        width: .4rem;
        height: .4rem;
      }
      .name {
        margin-top: .08rem;
        line-height: .3rem;
        color: #999;
        font-size: .2rem;
        font-weight: 500;
      }
    }
  }
}
</style>
